//RESET ALL
export const RESET_ALL_REDUCERS = "RESET_ALL_REDUCERS"

//
export const ON_START_LOADING = "ON_START_LOADING"

//USER
export const ON_USER_SIGN_IN_SUCCESS = "ON_USER_SIGN_IN_SUCCESS"
export const ON_USER_SIGN_IN_FAIL = "ON_USER_SIGN_IN_FAIL"
export const ON_USER_SIGN_OUT = "ON_USER_SIGN_OUT"
export const ON_USER_SIGN_IN_REQUEST = "ON_USER_SIGN_IN_REQUEST"
export const ON_CREATE_UPDATE_USER_REQUEST = "ON_CREATE_UPDATE_USER_REQUEST"
export const ON_UPDATE_USER_SUCCESS = "ON_UPDATE_USER_SUCCESS"
export const ON_CREATE_UPDATE_USER_ERROR =
  "ON_ON_CREATE_UPDATE_USER_ERRORUSER_SIGN_OUT"
export const CLEAR_USER_STATE = "CLEAR_USER_STATE"
export const CLEAR_REQUEST = "CLEAR_REQUEST"
export const ON_RESET_PASSWORD_SUCCESS = "ON_RESET_PASSWORD_SUCCESS"
export const ON_RESET_PASSWORD_FAIL = "ON_RESET_PASSWORD_FAIL"

//ORDER
export const ON_FETCH_READY_ORDERS_SUCCESS = "ON_FETCH_ORDERS_LIST_SUCCESS"
export const ON_FETCH_READY_ORDERS_FAIL = "ON_FETCH_ORDERS_LIST_FAIL"
export const ON_GET_ORDER_BY_CODE_SUCCESS = "ON_GET_ORDER_BY_CODE_SUCCESS"
export const ON_GET_ORDER_BY_CODE_FAIL = "ON_GET_ORDER_BY_CODE_FAIL"
export const ON_CLOSE_ORDER_REQ_SUCCESS = "ON_CLOSE_ORDER_REQ_SUCCESS"
export const ON_CLOSE_ORDER_REQ_FAIL = "ON_CLOSE_ORDER_REQ_FAIL"
export const ON_PUT_TO_LOCKER_SUCCESS = "ON_PUT_TO_LOCKER_SUCCESS"
export const ON_PUT_TO_LOCKER_FAIL = "ON_PUT_TO_LOCKER_FAIL"

//UPDATE ORDER
export const SET_ORDER_TO_UPDATE = "SET_ORDER_TO_UPDATE"
export const ON_UPDATE_ORDER_SUCCESS = "ON_UPDATE_ORDER_SUCCESS"
export const ON_UPDATE_ORDER_FAIL = "ON_UPDATE_ORDER_FAIL"
export const CLEAR_ORDER_UPDATE = "CLEAR_ORDER_UPDATE"
export const ON_FETCH_ORDERS_BY_PHONE_SUCCESS =
  "ON_FETCH_ORDERS_BY_PHONE_SUCCESS"
export const ON_FETCH_ORDERS_BY_PHONE_FAIL = "ON_FETCH_ORDERS_BY_PHONE_FAIL"

//ORDER STATUSES
export const ON_GET_ORDER_STATUSES_SUCCESS = "ON_GET_ORDER_STATUSES_SUCCESS"
export const ON_GET_ORDER_STATUSES_FAIL = "ON_GET_ORDER_STATUSES_FAIL"
export const ON_UPDATE_ORDER_STATUS_SUCCESS = "ON_UPDATE_ORDER_STATUS_SUCCESS"
export const ON_UPDATE_ORDER_STATUS_FAIL = "ON_UPDATE_ORDER_STATUS_FAIL"

export const ON_CREATE_RETURN_ORDER_SUCCESS = "ON_CREATE_RETURN_ORDER_SUCCESS"
export const ON_CREATE_RETURN_ORDER_FAIL = "ON_CREATE_RETURN_ORDER_FAIL"

export const ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS =
  "ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS"
export const ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL =
  "ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL"

//ON CLOSE MESSAGE
export const ON_CLOSE_MESSAGE = "ON_CLOSE_MESSAGE"

//LOCKER CELLS
export const FETCH_ALL_LOCKERS_STATUSES_SUCCESS =
  "FETCH_ALL_LOCKERS_STATUSES_SUCCESS"
export const FETCH_ALL_LOCKERS_LAYOUT_SUCCESS =
  "FETCH_ALL_LOCKERS_LAYOUT_SUCCESS"
export const CLEAR_LOCKERS_STATUSES = "CLEAR_LOCKERS_STATUSES"
export const FETCH_PUDO_LOCKERS_STATUSES_SUCCESS =
  "FETCH_PUDO_LOCKERS_STATUSES_SUCCESS"
export const FETCH_LOCKERS_STATUSES_NAMES_SUCCESS =
  "FETCH_LOCKERS_STATUSES_NAMES_SUCCESS"
export const SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS =
  "SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS"
export const SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL =
  "SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL"
export const CLEAR_EMAIL_SEND = "CLEAR_EMAIL_SEND"
export const LOADING_EMAIL_SEND = "LOADING_EMAIL_SEND"

//ORDER TYPE
export const ON_GET_ORDER_TYPES_SUCCESS = "  ON_GET_ORDER_TYPES_SUCCESS"
export const ON_GET_ORDER_TYPES_FAILURE = "    ON_GET_ORDER_TYPES_FAILURE"
export const ON_GET_ORDER_DETAILS_REQUEST = "ON_GET_ORDER_DETAILS_REQUEST"
