import { combineReducers } from "redux"
import UserReducer from "./UserReducer"
import OrderReducer from "./OrderReducer"
import OrderStatusesReducer from "./OrderStatusesReducer"
import LockersCellsReducer from "./LockersCellsReducer"
import OrderTypeReducer from "./OrderTypeReducer"

export default combineReducers({
  users: UserReducer,
  orders: OrderReducer,
  orderStatuses: OrderStatusesReducer,
  lockersCells: LockersCellsReducer,
  orderTypes: OrderTypeReducer,
})
