import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import SubTitle from "../../common/SubTitle"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
//import Loader from "../../common/Loader"
import OrderFoundModal from "../../common/OrderFoundModal"
//import { getFormatedDate } from "../../../utils/FormatedDate"
import {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderByCode,
  clearOrderUpdate,
  onCloseOrder,
} from "../../../actions"

import "./style.scss"

class OrderByCodeScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      code: "",
      showNotValidCodeMessage: false,
      showFoundOrder: true,
    }
  }

  clearState() {
    this.setState({
      code: "",
      showNotValidCodeMessage: false,
      showFoundOrder: true,
    })
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.clearOrderUpdate()
    this.clearState()
  }

  componentWillUnmount() {
    this.props.clearOrderUpdate()
    this.clearState()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, order } = newProps
    if (!authenticated) {
      history.push("/")
    }
  }

  onGetNewOrdersCkick() {
    this.props.history.push("/OrdersListScreen")
  }

  onChangeCode(e) {
    this.setState({ code: e.target.value })
  }

  onClickFindByCode() {
    const { code } = this.state
    const isCodeValid = this.isCodeValid(code)
    if (isCodeValid) {
      this.props.onStartLoading()
      this.props.getOrderByCode(code)
      this.setState({ showFoundOrder: true, code: "" })
    } else {
      this.setState({ showNotValidCodeMessage: true })
    }
  }

  isCodeValid(deliveryCode) {
    if (
      deliveryCode == undefined ||
      !deliveryCode ||
      deliveryCode.length === 0
    ) {
      return false
    }
    return deliveryCode.length === 6
  }
  onCancelClick() {
    this.clearState()
    this.props.clearOrderUpdate()
  }

  userTakeFromLocker(order) {
    this.props.onStartLoading()
    this.props.onCloseOrder(order.id, order.locationId)
  }

  render() {
    const { isLoading, isError, orderByCode } = this.props
    const { showFoundOrder } = this.state
    return (
      <div className="order-by-code-wrapper">
        <Title text="מסירה" />
        <SubTitle text=" הכנס קוד " />
        <InputField
          maxLength="6"
          isFocused={true}
          show={true}
          placeholder={" הכנס קוד מסמס"}
          value={this.state.code}
          onChange={(e) => this.onChangeCode(e)}
          type="tel"
        />

        <div className="button-wrapper">
          <Button onClick={() => this.onClickFindByCode()}>חפש</Button>
        </div>

        <div className="right-bottom-box-wrapper">
          <Button onClick={() => this.props.history.push("LockersListScreen")}>
            מלאי
          </Button>
        </div>

        <div className="left-bottom-box-wrapper">
          <Button
            onClick={() =>
              this.props.history.push("LoadOrderByPackageNumberScreen")
            }
          >
            קליטה
          </Button>
        </div>

        <OrderFoundModal
          show={showFoundOrder}
          order={orderByCode}
          isLoading={isLoading}
          isError={isError}
          onOKClick={(e) => this.userTakeFromLocker(e)}
          onCancelClick={(e) => this.onCancelClick(e)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, orderStatuses }) => {
  const { authenticated } = users
  const { isLoading, isError, orderByCode } = orders
  const { ordersStatusesArr } = orderStatuses

  return {
    authenticated,
    isLoading,
    isError,
    ordersStatusesArr,
    orderByCode,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderByCode,
  clearOrderUpdate,
  onCloseOrder,
})(OrderByCodeScreen)
