import axios from "axios"
import api from "../api/BaseApi"
import {
  ON_START_LOADING,
  ON_FETCH_READY_ORDERS_SUCCESS,
  ON_FETCH_READY_ORDERS_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_GET_ORDER_STATUSES_SUCCESS,
  ON_CLOSE_MESSAGE,
  ON_GET_ORDER_STATUSES_FAIL,
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
  ON_GET_ORDER_DETAILS_REQUEST,
  CLEAR_ORDER_UPDATE,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL,
  ON_GET_ORDER_BY_CODE_SUCCESS,
  ON_GET_ORDER_BY_CODE_FAIL,
  ON_CLOSE_ORDER_REQ_SUCCESS,
  ON_CLOSE_ORDER_REQ_FAIL,
  ON_PUT_TO_LOCKER_SUCCESS,
  ON_PUT_TO_LOCKER_FAIL,
} from "../actions/types"

export const onStartLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOADING,
    })
  }
}

export const clearOrderUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_UPDATE,
    })
  }
}

export const fetchReadyStationOrders = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getReadyStationOrders, options)
      .then((res) => {
        dispatch({
          type: ON_FETCH_READY_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          dispatch({
            type: ON_FETCH_READY_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              //errText: err.response.status
              //errTitle: 'err_title_text',     //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
            },
          })

          return
        }

        dispatch({
          type: ON_FETCH_READY_ORDERS_FAIL,
          payload: {
            //TODO
            //errTitle: err.response.data.message,
            //errText: err.response.status
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
          },
        })
      })
  }
}

export const setSelectedOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_TO_UPDATE,
      payload: order,
    })
  }
}

export const closeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_MESSAGE,
    })
  }
}

export const onUpdateOrder = (order) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const body = [
      {
        mobilePhone: order.mobilePhone,
        stationNumber: order.stationNumber,
        packageNumber: order.packageNumber,
        firstName: order.firstName,
        lastName: order.lastName,
        orderStatus: parseInt(order.orderStatus),
        inLockerExtentionDate: order.inLockerExtentionDate,
      },
    ]
    axios
      .post(api.updateOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const fetchOrderStatuses = () => {
  return (dispatch) => {
    axios
      .get(api.getOrderStatuses)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_FAIL,
          payload: err,
        })
      })
  }
}

export const fetchOrderTypes = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getOrderTypes)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_FAILURE,
          payload: err,
        })
      })
  }
}

export const getOrderByCode = (code) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })

    axios
      .get(api.getOrderByCode(code))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_BY_CODE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_BY_CODE_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}

export const onCloseOrder = (orderId, lockerId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .put(api.onCloseOrder(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_CLOSE_ORDER_REQ_SUCCESS,
        })
      })
      .catch((err) => {
        console.log("onCloseOrder > " + err)
        dispatch({
          type: ON_CLOSE_ORDER_REQ_FAIL,
        })
      })
  }
}

export const putToLocker = (orderId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .put(api.putToLocker(orderId))
      .then((res) => {
        dispatch({
          type: ON_PUT_TO_LOCKER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("putToLocker > " + err)
        dispatch({
          type: ON_PUT_TO_LOCKER_FAIL,
          payload: err,
        })
      })
  }
}

export const updateOrderStatus = (orderId, orderStatus) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.updateOrderStatus(orderId, orderStatus), options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const updateOrderStatusByAccount = (
  orderId,
  orderStatus,
  inLockerExtentionDate
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .post(
        api.updateOrderStatusByAccount(
          orderId,
          orderStatus,
          inLockerExtentionDate
        ),
        options
      )
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL,
          payload: {
            errTitle: err.response.data?.message,
            errText: err.response.status,
          },
        })
      })
  }
}
