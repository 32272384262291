import React from "react"

import "./style.css"

const SmallHeader = ({ title = "כניסה למערכת" }) => {
  const logoSrc = require("../../../assets/images/logo.png")

  return (
    <div className="sml-header-wrapper">
      <div className="sml-header-title">{title}</div>
    </div>
  )
}

export { SmallHeader }
