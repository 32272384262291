export const  getFormatedDate = (dateStr) => {
    if(dateStr == null) return '';
    const dateObj = new Date(dateStr+'+00:00');
    const result = dateObj.toLocaleString('he-IL', { timeZone: 'Israel' }).replace(/\./g, '/');
    
    if (dateObj.getFullYear() === 1) {
        return 'בתהליך';
    }

    return result;

};

export const  getFormatedDateOnly = (dateStr) => {
if(dateStr == null) return '';
const dateObj = new Date(dateStr+'+00:00');
const result = dateObj.toLocaleDateString('he-IL', { timeZone: 'Israel' }).replace(/\./g, '/');

if (dateObj.getFullYear() === 1) {
    return 'בתהליך';
}

return result;

};

export const getShortDate =  (dateStr) => {
if(dateStr === null || dateStr === undefined || dateStr === '') return '';
return dateStr.substr(0, 10);
}
