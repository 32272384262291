const api = {}

const BASE_URL = "https://bo-api.cleanbox.co.il" //aws pudo prod
//const BASE_URL = "https://bo-api-test.cleanbox.co.il" //test
//const BASE_URL = "https://localhost:5001" //local

//LOGIN
api.signIn = `${BASE_URL}/api/Pudo/Authenticate`
api.signInBody = (mobilePhone, pwd) => {
  return {
    mobilePhone: mobilePhone,
    password: pwd,
  }
}

//USERS
api.updateUserPassword = () => `${BASE_URL}/api/Pudo/update_password`
api.resetPassword = (userId) => `${BASE_URL}/api/Pudo/reset_password/${userId}`

//ORDERS
api.getOrderByCode = (code) => `${BASE_URL}/api/Pudo/get_order_by_code/${code}`

api.getReadyStationOrders = `${BASE_URL}/api/Pudo/get_ready`
api.putToLocker = (orderId) => `${BASE_URL}/api/Pudo/put_to_locker/${orderId}`

//TAKE ORDER FOROM LOCKER
api.onCloseOrder = (orderId, lockerId) =>
  `${BASE_URL}/api/Pudo/close_order/${orderId}?lockerId=${lockerId}`

//ORDER TYPES NAMES
api.getOrderTypes = `${BASE_URL}/api/Pudo/get_types`

//ORDER STATUSES NAMES
api.getOrderStatuses = `${BASE_URL}/api/Pudo/get_status_types`

//GET LOCKER STATUSES NAMES
api.getLockersStatusesNames = `${BASE_URL}/api/Pudo/get_lockers_statuses`

//GET LOCKER STATUSES
api.getLockersStatusesForStation = (stationId) =>
  `${BASE_URL}/api/Pudo/lockers_status/${stationId}`

api.getPudoLockersStatuses = `${BASE_URL}/api/Pudo/pudo_lockers_status`

api.sendPudoLockersStatusesByEmail = `${BASE_URL}/api/Pudo/email_send`

//GET LOCKERS LAYOUT
api.getLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/Pudo/get_layout/${stationId}`

export default api
