import React from "react"
import Modal from "../Modal"
import { SmallHeader } from "../SmallHeader"
import { Button } from "../Button"
import Loader from "../Loader"
import "./style.scss"

const OrderFoundModal = ({
  show,
  order,
  isError,
  isLoading,
  onOKClick,
  onCancelClick,
}) => {
  if (!show) {
    return <noscript />
  }
  if (isError) {
    return (
      <Modal extraClass="found-order-modal-wrap">
        <div className={`found-order-container`}>
          <SmallHeader title=" חיפוש הזמנה לפי קוד " />
          <div className="message-not-found-message-wrapper">
            ההזמנה לא נמצאה
          </div>
          <div className="btn-holder">
            <Button onClick={(e) => onCancelClick()}>סגור</Button>
          </div>
        </div>
      </Modal>
    )
  }
  if (isLoading) {
    return (
      <Modal extraClass="found-order-modal-wrap">
        <div className={`found-order-container`}>
          <SmallHeader title="חיפוש הזמנה לפי קוד" />
          <Loader show={true} />
        </div>
      </Modal>
    )
  }
  if (!order) {
    return <noscript />
  }
  const { orderNumber, firstName, lastName, currentLocation } = order
  return (
    <Modal extraClass="found-order-modal-wrap">
      <div className={`found-order-container`}>
        <SmallHeader title="הזמנה נמצאה" />

        <div className="order-details">
          <div className="row">
            <div className="label">מספר הזמנה </div>
            <div className="value order-number-wrapper"> {orderNumber}</div>
          </div>
          <div className="row">
            <div className="label"> שם </div>
            <div className="value name-wrapper">
              {firstName} {lastName}
            </div>
          </div>
          <div className="row">
            <div className="label">מיקום הזמנה </div>
            <div className="value location-wrapper">{currentLocation}</div>
          </div>
        </div>

        <div className="btn-holder">
          <Button onClick={() => onCancelClick()}>ביטול קבלה</Button>
          <Button onClick={() => onOKClick(order)}>אישור קבלה</Button>
        </div>
      </div>
    </Modal>
  )
}

export default OrderFoundModal
