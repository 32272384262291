import {
  ON_GET_ORDER_STATUSES_SUCCESS,
  ON_GET_ORDER_STATUSES_FAIL,
} from "../actions/types"

import { setOrderStatusesEnumByLang } from "../utils/OrderStatuses"

const INIT_STATE = {
  ordersStatusesArr: [],
}

const OrdersStatusesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDER_STATUSES_SUCCESS:
      return {
        ...state,
        ordersStatusesArr: setStatusesArray(action.payload),
      }
    case ON_GET_ORDER_STATUSES_FAIL:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

const setStatusesArray = (statuses) => {
  if (statuses === undefined || statuses === null) return []
  return setOrderStatusesEnumByLang(statuses, "he")
}

export default OrdersStatusesReducer
