export const setOrderStatusesEnumByLang = (obj, lang) => {
  const items = []
  for (const [key, value] of Object.entries(obj)) {
    switch (lang) {
      case "en":
        items[value.id] = value.statusName
        break
      case "he":
        items[value.id] = value.statusName_he
        break
      default:
        items[value.id] = value.statusName_he
    }
  }
  return items
}

export const getStatusIndexFromARR = (ARR, value) => ARR.indexOf(value)
