import React from "react"
import { ButtonRectangle } from "../ButtonRectangle"
import { SmallHeader } from "../SmallHeader"

import Loader from "../Loader"
import Modal from "../Modal"
import "./style.scss"

const SendEmailModal = ({
  show,
  isLoading,
  isError,
  emailIsSended,
  onOkClick,
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal extraClass="noti-modal-wrap">
      <div className="noti-modal-container">
        <SmallHeader title="שליחת מלאי באמייל" />

        {isLoading && (
          <div className="send-email-content-wrapper">
            <Loader show={true} />
          </div>
        )}
        {isError && (
          <>
            <div className="end-email-content-wrapper">הפעולה נכשלה</div>
            <div className="btn-holder">
              <ButtonRectangle
                extraClass="noti-modal-button"
                onClick={(e) => onOkClick()}
              >
                אישור
              </ButtonRectangle>
            </div>
          </>
        )}

        {emailIsSended && (
          <>
            <div className="end-email-content-wrapper">אמייל נשלח בהצלחה</div>
            <div className="btn-holder">
              <ButtonRectangle
                extraClass="noti-modal-button"
                onClick={(e) => onOkClick()}
              >
                אישור
              </ButtonRectangle>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default SendEmailModal
