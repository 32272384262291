import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  CLEAR_LOCKERS_STATUSES,
  FETCH_PUDO_LOCKERS_STATUSES_SUCCESS,
  FETCH_LOCKERS_STATUSES_NAMES_SUCCESS,
  SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS,
  SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL,
  CLEAR_EMAIL_SEND,
  LOADING_EMAIL_SEND,
} from "../actions/types"

const INIT_STATE = {
  lockersData: [],
  lockersLayout: [],
  maxLockerNumber: 0,
  brokenLockersNumArr: [],
  nextAllAvailableCells: null,
  nextAvailableCellIndex: 0,
  nextAvailableCellNumber: null,
  isAnyOrderToReturn: false,
  lockerStatuesNames: [],
  isLoading: false,
  emailIsSended: false,
}

const LockersCellsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_LOCKERS_STATUSES_SUCCESS:
      return handleLockersStatusesResponse(state, action.payload)

    case FETCH_PUDO_LOCKERS_STATUSES_SUCCESS:
      return {
        ...state,
        pudoLockers: action.payload,
      }
    case FETCH_LOCKERS_STATUSES_NAMES_SUCCESS:
      return {
        ...state,
        lockerStatuesNames: action.payload,
      }
    case FETCH_ALL_LOCKERS_LAYOUT_SUCCESS:
      return {
        ...state,
        lockersLayout: action.payload,
      }
    case SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        emailIsSended: true,
      }
    case SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        emailIsSended: false,
      }
    case LOADING_EMAIL_SEND:
      return {
        ...state,
        isLoading: true,
        isError: false,
        emailIsSended: false,
      }
    case CLEAR_EMAIL_SEND:
      return {
        ...state,
        isLoading: false,
        isError: false,
        emailIsSended: false,
      }
    case CLEAR_LOCKERS_STATUSES:
      return {
        lockersData: [],
        lockersLayout: [],
        maxLockerNumber: 0,
        brokenLockersNumArr: [],
        nextAllAvailableCells: null,
        nextAvailableCellIndex: 0,
        nextAvailableCellNumber: null,
        isAnyOrderToReturn: false,
        lockerStatuesNames: [],
        isLoading: false,
        emailIsSended: false,
      }
    default:
      return state
  }
}

const handleLockersStatusesResponse = (state, respData) => {
  const lockersData = respData.sort((a, b) => a.lockerName - b.lockerName)

  const nextAllAvailableCells = lockersData.filter(
    (itm) => itm.lockerStatus === 3 && itm.lockerType === 0
  )
  const nextCell = nextAllAvailableCells[0] || undefined
  const nextAvailableCellNumber = nextCell
    ? parseInt(nextCell.lockerName, 10)
    : undefined
  const maxLockerNumber = lockersData.reduce(
    (accu, { lockerName }) =>
      accu < parseInt(lockerName) ? parseInt(lockerName) : accu,
    0
  )
  const brokenLockersNumArr = lockersData
    .filter((itm) => itm.lockerStatus === 0)
    .map(({ lockerName }) => parseInt(lockerName))
  const isAnyOrderToReturn =
    lockersData.filter(
      (itm) =>
        !itm.disabled &&
        !itm.disabledToPickUp &&
        itm.order !== null &&
        itm.order.orderStatus === 13
    ).length > 0

  return {
    ...state,
    lockersData,
    nextAllAvailableCells,
    nextAvailableCellIndex: 0,
    nextAvailableCellNumber,
    maxLockerNumber,
    brokenLockersNumArr,
    isAnyOrderToReturn,
  }
}

export default LockersCellsReducer
