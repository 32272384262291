import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import SubTitle from "../../common/SubTitle"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import PutToLockerOrderModal from "../../common/PutToLockerOrderModal"
import BarcodeScanner from "../../common/BarcodeScanner"
import NotificationModal from "../../common/NotificationModal"

import {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  clearOrderUpdate,
  fetchReadyStationOrders,
  putToLocker,
} from "../../../actions"

import "./style.scss"

class LoadOrderByPackageNumberScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      packageNumber: "",
      showNotValidCodeMessage: false,
      showFoundOrder: false,
      order: null,
      showNoOrderFoundModal: false,
    }
  }

  clearState() {
    this.setState({
      packageNumber: "",
      showNotValidCodeMessage: false,
      showFoundOrder: false,
      order: null,
    })
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.clearOrderUpdate()
    this.props.fetchReadyStationOrders()
    this.clearState()
  }

  componentWillUnmount() {
    this.props.clearOrderUpdate()
    this.clearState()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history } = newProps
    if (!authenticated) {
      history.push("/")
    }
  }

  onGetNewOrdersCkick() {
    this.props.history.push("/OrdersListScreen")
  }

  onChangeCode(e) {
    this.setState({ packageNumber: e.target.value })
  }

  onScanBarcode(result) {
    this.setState({ packageNumber: result })
    this.onClickFindByPackageNumber()
  }

  onClickFindByPackageNumber() {
    const { packageNumber } = this.state
    const isPackageNumberValid = this.isPackageNumberValid(packageNumber)
    if (isPackageNumberValid) {
      this.getOrderByPackageNumber(packageNumber)
    } else {
      this.setState({ showNotValidCodeMessage: true })
    }
  }

  getOrderByPackageNumber(packageNumber) {
    const orderByPackageNumber = this.props.orderList.find(
      (o) => o.packageNumber === packageNumber || o.orderNumber == packageNumber
    )

    if (orderByPackageNumber) {
      this.props.onStartLoading()
      this.props.putToLocker(orderByPackageNumber.orderId)
      this.setState({ showFoundOrder: true, order: orderByPackageNumber })
    } else {
      this.setState({ showNoOrderFoundModal: true })
    }
  }

  isPackageNumberValid(value) {
    if (value == undefined || !value || value.length === 0) {
      return false
    }
    return value.length > 3
  }
  onCloseClick() {
    this.props.clearOrderUpdate()
    this.props.fetchReadyStationOrders()
    this.clearState()
  }

  driverPutToFirstEmptyLocker(order) {
    this.props.onStartLoading()
    this.props.putToLocker(order.id)
  }

  render() {
    const { isLoading, isError, orderToPutToLocker, isNoEmptyLocker } =
      this.props
    let lockerName = orderToPutToLocker ? orderToPutToLocker.lockerName : null

    const { order, showNoOrderFoundModal } = this.state

    const { showFoundOrder } = this.state

    return (
      <div className="put-to-locker-wrapper">
        <Title text="קליטה" />
        <SubTitle text="סרוק מספר חבילה" />
        {false && (
          <InputField
            maxLength="50"
            isFocused={true}
            show={true}
            placeholder={"מספר חבילה"}
            value={this.state.packageNumber}
            onChange={(e) => this.onChangeCode(e)}
            disabled={true}
          />
        )}

        {false && (
          <div className="button-wrapper">
            <Button onClick={() => this.onClickFindByPackageNumber()}>
              חפש
            </Button>
          </div>
        )}
        <BarcodeScanner onScanBarcode={(e) => this.onScanBarcode(e)} />
        <div className="right-bottom-box-wrapper">
          <Button onClick={() => this.props.history.push("LockersListScreen")}>
            מלאי
          </Button>
        </div>

        <div className="left-bottom-box-wrapper">
          <Button onClick={() => this.props.history.push("OrderByCodeScreen")}>
            מסירה
          </Button>
        </div>

        <PutToLockerOrderModal
          show={showFoundOrder}
          order={order}
          locker={lockerName}
          isLoading={isLoading}
          isError={isError}
          isNoEmptyLocker={isNoEmptyLocker}
          onCloseClick={(e) => this.onCloseClick(e)}
        />

        <NotificationModal
          show={showNoOrderFoundModal}
          text={"לא נמצאה חבילה לטעינה"}
          onOkClick={() =>
            this.setState({ showNoOrderFoundModal: false, packageNumber: "" })
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, orderStatuses }) => {
  const { authenticated } = users
  const { isLoading, isError, orderToPutToLocker, orderList, isNoEmptyLocker } =
    orders
  const { ordersStatusesArr } = orderStatuses

  return {
    authenticated,
    isLoading,
    isError,
    ordersStatusesArr,
    orderToPutToLocker,
    orderList,
    isNoEmptyLocker,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  clearOrderUpdate,
  fetchReadyStationOrders,
  putToLocker,
})(LoadOrderByPackageNumberScreen)
