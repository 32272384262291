import React from "react"
import Modal from "../Modal"
import { SmallHeader } from "../SmallHeader"
import Title from "../Title"
import { Button } from "../Button"
import Loader from "../Loader"
import "./style.scss"

const PutToLockerOrderModal = ({
  show,
  order,
  locker,
  isError,
  isNoEmptyLocker,
  isLoading,
  onCloseClick,
}) => {
  if (!show) {
    return <noscript />
  }
  if (isError && isNoEmptyLocker) {
    return (
      <Modal extraClass="put-to-locker-modal-wrap">
        <div className={`put-to-locker-container`}>
          <SmallHeader title="טעינת חבילה למלאי" />
          <div className="message-not-found-message-wrapper">
            לא ניתן לטעון חבילה למלאי
            <br />
            אין מקום
          </div>
          <div className="btn-holder">
            <Button onClick={(e) => onCloseClick()}>סגור</Button>
          </div>
        </div>
      </Modal>
    )
  }
  if (isError) {
    return (
      <Modal extraClass="put-to-locker-modal-wrap">
        <div className={`put-to-locker-container`}>
          <SmallHeader title="טעינת חבילה למלאי" />
          <div className="message-not-found-message-wrapper">
            חבילה לא נמצאה
          </div>
          <div className="btn-holder">
            <Button onClick={(e) => onCloseClick()}>סגור</Button>
          </div>
        </div>
      </Modal>
    )
  }
  if (isLoading) {
    return (
      <Modal extraClass="put-to-locker-modal-wrap">
        <div className={`put-to-locker-container`}>
          <SmallHeader title="טעינת חבילה למלאי" />
          <Loader show={true} />
        </div>
      </Modal>
    )
  }
  if (!order) {
    return <noscript />
  }
  const { orderNumber, customerName, packageNumber, accountType } = order
  return (
    <Modal extraClass="put-to-locker-modal-wrap">
      <div className={`put-to-locker-container`}>
        <SmallHeader title="טעינת חבילה למלאי " />

        <div className="order-details">
          <div className="row">
            <div className="label">מספר הזמנה </div>
            <div className="value order-number-wrapper"> {orderNumber}</div>
          </div>
          <div className="row">
            <div className="label">מספר חבילה </div>
            <div className="value order-number-wrapper"> {packageNumber}</div>
          </div>
          <div className="row">
            <div className="label"> סוג משלוח </div>
            <div className="value order-number-wrapper"> {accountType}</div>
          </div>

          <div className="row">
            <div className="label"> שם </div>
            <div className="value name-wrapper">{customerName}</div>
          </div>
        </div>
        <Title text="הכנס חבילה למיקום" />
        <div className="location-wrapper">{locker}</div>
        <div className="btn-holder">
          <Button onClick={() => onCloseClick(order)}>סגור </Button>
        </div>
      </div>
    </Modal>
  )
}

export default PutToLockerOrderModal
