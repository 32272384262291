import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"

import {
  onStartLoading,
  fetchOrderStatuses,
  fetchPudoLockersStatuses,
  fetchOrderTypes,
  fetchLockersStatusesNames,
  sendLockerStatusByEmail,
  clearEmailSend,
  loadingEmailSend,
} from "../../../actions"

import "./style.scss"
import LockersList from "./LockersList"
import SendEmailModal from "../../common/SendEmailModal"

class LockersListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderExternalNumbers: null,
      isShowHistory: false,
      isShowExternalNumbers: false,
      isShowPDF: false,
      packageNumber: null,
      showEmailModal: false,
      selectedOrder: null,
      orderId: null,
      seletedOrder: null,
      currentOrderInLockerDate: null,
    }
  }

  componentDidMount() {
    const { authenticated, history, userData } = this.props
    if (!authenticated) {
      history.push("/")
    }

    this.props.onStartLoading()
    this.props.fetchOrderTypes()
    this.props.fetchOrderStatuses()
    this.props.fetchPudoLockersStatuses()
    //this.props.fetchLockersStatusesNames()
  }

  componentDidUpdate() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    if (this.props.orderStatusUpdateSuccess) {
      this.props.fetchPudoLockersStatuses()
    }
  }

  refreshTable() {
    this.props.fetchPudoLockersStatuses()
  }

  onCloseEmailSendModal() {
    this.props.clearEmailSend()
    this.setState({
      showEmailModal: false,
    })
  }

  render() {
    const { isLoading, isError, emailIsSended, ordersStatusesArr } = this.props
    const { showEmailModal } = this.state
    return (
      <div>
        <Title text="רשימת מלאי" />
        {ordersStatusesArr && ordersStatusesArr.length > 0 ? (
          <LockersList
            refreshTable={() => this.refreshTable()}
            sendLockerStatusByEmail={() => {
              this.setState({ showEmailModal: true })
              this.props.loadingEmailSend()
              this.props.sendLockerStatusByEmail()
            }}
          />
        ) : (
          <Loader show={true} />
        )}

        <SendEmailModal
          show={showEmailModal}
          isLoading={isLoading}
          emailIsSended={emailIsSended}
          isError={isError}
          onOkClick={() => this.onCloseEmailSendModal()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orderStatuses, lockersCells }) => {
  const { authenticated, userData } = users
  const { pudoLockers, isLoading, isError, emailIsSended } = lockersCells

  const { ordersStatusesArr } = orderStatuses

  return {
    authenticated,
    isLoading,
    isError,
    emailIsSended,
    pudoLockers,
    userData,
    ordersStatusesArr,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  fetchOrderStatuses,
  fetchPudoLockersStatuses,
  fetchOrderTypes,
  fetchLockersStatusesNames,
  sendLockerStatusByEmail,
  clearEmailSend,
  loadingEmailSend,
})(LockersListScreen)
