import {
  ON_START_LOADING,
  ON_FETCH_READY_ORDERS_SUCCESS,
  ON_FETCH_READY_ORDERS_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_CLOSE_MESSAGE,
  RESET_ALL_REDUCERS,
  ON_GET_ORDER_DETAILS_REQUEST,
  CLEAR_ORDER_UPDATE,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  ON_GET_ORDER_BY_CODE_SUCCESS,
  ON_GET_ORDER_BY_CODE_FAIL,
  ON_CLOSE_ORDER_REQ_SUCCESS,
  ON_CLOSE_ORDER_REQ_FAIL,
  ON_PUT_TO_LOCKER_SUCCESS,
  ON_PUT_TO_LOCKER_FAIL,
} from "../actions/types"

const INIT_STATE = {
  orderList: null,
  selectedOrder: null,
  errTitle: null,
  errText: null,
  isLoading: false,
  isError: false,
  showMessage: false,
  response: null,
  orderDetails: null,
  orderStatusUpdateSuccess: false,
  orderStatusUpdateFail: false,
  orderStatusUpdateByAccountSuccess: false,
  orderStatusUpdateByAccountFail: false,
  isNoEmptyLocker: false,
}

const OrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_START_LOADING:
      return {
        ...INIT_STATE,
        isLoading: true,
      }
    case CLEAR_ORDER_UPDATE:
      return {
        ...state,
        selectedOrder: null,
        errTitle: null,
        errText: null,
        isLoading: false,
        isError: false,
        showMessage: false,
        response: null,
        orderDetails: null,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: false,
        createLockerToLockerSuccess: false,
        createLockerToLockerFail: false,
        orderStatusUpdateByAccountSuccess: false,
        orderStatusUpdateByAccountFail: false,
        orderByCode: null,
        orderToPutToLocker: { orderId: null, lockerId: null, lockerName: null },
        isNoEmptyLocker: false,
      }
    case ON_GET_ORDER_BY_CODE_SUCCESS:
      return {
        ...state,
        orderByCode: action.payload,
        isLoading: false,
        showMessage: false,
        isError: false,
      }
    case ON_GET_ORDER_BY_CODE_FAIL:
      return {
        ...state,
        orderByCode: null,
        isLoading: false,
        showMessage: true,
        isError: true,
      }

    case ON_CLOSE_ORDER_REQ_SUCCESS:
      return {
        ...state,
        orderByCode: null,
        isLoading: false,
        isError: false,
      }
    case ON_CLOSE_ORDER_REQ_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case ON_FETCH_READY_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.data,
        isLoading: false,
        showMessage: false,
        isError: false,
      }
    case SET_ORDER_TO_UPDATE:
      return {
        ...state,
        selectedOrder: action.payload,
        showMessage: false,
        isError: false,
      }
    case ON_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        response: action.payload.data[0],
        isError: !action.payload.data[0].status,
        showMessage: true,
        isLoading: false,
      }
    case ON_FETCH_READY_ORDERS_FAIL:
    case ON_UPDATE_ORDER_FAIL:
      return {
        ...state,
        isError: true,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        showMessage: true,
        isLoading: false,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    case ON_GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetails: null,
      }

    case ON_UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatusUpdateSuccess: true,
        orderStatusUpdateFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: true,
      }
    case ON_PUT_TO_LOCKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orderToPutToLocker: action.payload,
      }
    case ON_PUT_TO_LOCKER_FAIL:
      const isNoEmptyLocker = action.payload.response.status === 500
      return {
        ...state,
        isLoading: false,
        isError: true,
        orderToPutToLocker: { orderId: null, lockerId: null, lockerName: null },
        isNoEmptyLocker,
      }
    default:
      return state
  }
}

export default OrderReducer
