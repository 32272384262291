import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import MenuItem from "./MenuItem"
import OpenMenuButton from "./OpenMenuButton"
import "./style.scss"

class Menu extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isActive: false,
    }

    this.state = this.INIT_STATE
  }

  setActiveMenu(value) {
    this.setState({ isActive: value })
  }

  componentDidMount() {
    document.addEventListener("click", (event) => {
      const menu = document.getElementById("menu")
      let targetElement = event.target // clicked element

      do {
        if (targetElement == menu) {
          // This is a click inside. Do nothing, just return.
          return
        }
        // Go up the DOM
        targetElement = targetElement.parentNode
      } while (targetElement)
      // This is a click outside.
      this.setActiveMenu(false)
    })
  }

  isShowMenu() {
    const { history } = this.props
    if (
      history.location.pathname == "/" ||
      history.location.pathname == "/DecathlonStationSelectScreen"
    ) {
      return false
    }
    return true
  }

  render() {
    const {
      text,
      history,
      isAuthenticated,
      accountType,
      isAllowCreateOrder,
      ...props
    } = this.props
    return this.isShowMenu() ? (
      <div className="menu-wrapper" id="menu">
        <OpenMenuButton
          isActive={this.state.isActive}
          onClickMe={(e) => this.setActiveMenu(e)}
        />
        <div className="menu-items-wrapper">
          <MenuItem
            show={this.state.isActive}
            text="מסירת חבילה"
            onClick={(e) => {
              history.push("/OrderByCodeScreen")
              this.setActiveMenu(false)
            }}
          />
          <MenuItem
            show={this.state.isActive}
            text="קליטת חבילה"
            onClick={(e) => {
              history.push("/LoadOrderByPackageNumberScreen")
              this.setActiveMenu(false)
            }}
          />
          {/*<MenuItem
            show={this.state.isActive}
            text="תצוגת לוקרים"
            onClick={(e) => {
              history.push("/StationStatusScreen")
              this.setActiveMenu(false)
            }}
          />*/}
          <MenuItem
            show={this.state.isActive}
            text="רשימת מלאי"
            onClick={(e) => {
              history.push("/LockersListScreen")
              this.setActiveMenu(false)
            }}
          />

          <MenuItem
            show={this.state.isActive}
            text="התנתק"
            onClick={(e) => {
              history.push("/")
              this.setActiveMenu(false)
            }}
          />
        </div>
      </div>
    ) : (
      <></>
    )
  }
}

export default withRouter(Menu)
