import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
  ON_USER_SIGN_IN_REQUEST,
  ON_CLOSE_MESSAGE,
  ON_CREATE_UPDATE_USER_REQUEST,
  ON_UPDATE_USER_SUCCESS,
  ON_CREATE_UPDATE_USER_ERROR,
  CLEAR_USER_STATE,
  CLEAR_REQUEST,
  ON_RESET_PASSWORD_SUCCESS,
  ON_RESET_PASSWORD_FAIL,
} from "../actions/types"

const emptyUser = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
}

const INIT_STATE = {
  userData: null,
  authenticated: false,
  errTitle: null,
  errText: null,
  isLoading: false,
  token: null,
  showMessage: false,
  loadingCreateUpdateRequest: false,
  createUpdateRequestError: null,
  createUpdateRequsetSuccess: false,

  usersListArr: [],
  user: emptyUser,

  isUserExist: false,
  isPasswordResetSucces: false,
  isPasswordResetFail: false,
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_USER_SIGN_IN_REQUEST:
      return {
        ...INIT_STATE,
        isLoading: true,
      }
    case ON_USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        authenticated: true,
        isLoading: false,
        showMessage: false,
      }
    case ON_USER_SIGN_IN_FAIL:
      return {
        ...state,
        userData: null,
        authenticated: false,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        isLoading: false,
        showMessage: true,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case ON_USER_SIGN_OUT:
      return {
        ...INIT_STATE,
      }

    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    case ON_CREATE_UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingCreateUpdateRequest: true,
        createUpdateRequestError: null,
        createUpdateRequsetSuccess: false,
      }
    case ON_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequsetSuccess: true,
      }
    case ON_CREATE_UPDATE_USER_ERROR:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequestError: true,
      }
    case CLEAR_USER_STATE:
      return {
        ...INIT_STATE,
      }
    case CLEAR_REQUEST:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequestError: null,
        createUpdateRequsetSuccess: false,
        isPasswordResetSucces: false,
        isPasswordResetFail: false,
      }
    case ON_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordResetSucces: true,
        isPasswordResetFail: false,
      }
    case ON_RESET_PASSWORD_FAIL:
      return {
        ...state,
        isPasswordResetSucces: false,
        isPasswordResetFail: true,
      }
    default:
      return state
  }
}

export default UserReducer
