import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
//import history_icon from "../../../../assets/svg/history_icon.svg"
//import list_icon from "../../../../assets/svg/list_icon.svg"

import "./style.scss"

const LockersList = (props) => {
  let [data] = useState([])
  const {
    pudoLockers,
    ordersStatusesArr,
    refreshTable,
    sendLockerStatusByEmail,
  } = props

  const nameMapping = {
    0: "שבור",
    1: "מלא", //1: "לאיסוף",
    2: "מלא", //2: "מוכן",
    3: "ריק",
  }

  const formateLocker = (pudoLocker) => {
    const {
      lockerId,
      lockerName,
      lockerStatus,
      orderId,
      orderNumber,
      packageNumber,
      externalOrderNumber,
      firstName,
      lastName,
      orderStatus,
      orderDate,
    } = pudoLocker

    return {
      lockerId,
      lockerName,
      lockerStatus: nameMapping[lockerStatus], //lockerStatuesNames[lockerStatus],
      orderId,
      orderNumber: orderNumber === 0 ? "" : orderNumber,
      packageNumber,
      externalOrderNumber,
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      orderStatus: orderStatus === 0 ? "" : ordersStatusesArr[orderStatus],
      orderDate: orderNumber === 0 ? "" : getFormatedDate(orderDate),
    }
  }

  const lockersFormated = () => {
    const { pudoLockers } = props

    if (pudoLockers) {
      return pudoLockers
        ? pudoLockers.map((locker) => formateLocker(locker))
        : []
    } else {
      return []
    }
  }

  data = useMemo(() => lockersFormated(), [pudoLockers])

  const columns = useMemo(() => {
    const result = [
      {
        Header: "מיקום",
        accessor: "lockerName",
      },

      {
        Header: " סטטוס מיקום",
        accessor: "lockerStatus",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "מספר הזמנה",
        accessor: "orderNumber",
      },
      {
        Header: "מספר חבילה",
        accessor: "packageNumber",
      },
      {
        Header: "מספר חבילה חיצוני",
        accessor: "externalOrderNumber",
      },
      {
        Header: "סטטוס הזמנה",
        accessor: "orderStatus",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "שם פרטי",
        accessor: "firstName",
      },
      {
        Header: "שם משפחה",
        accessor: "lastName",
      },
      {
        Header: "תאריך",
        accessor: "orderDate",
      },
    ]

    /* result.splice(2, 0, {
      Header: "מספר חבילה",
      accessor: "packageNumber",
    })

   

   result.splice(0, 0, {
      Header: () => null,
      id: "list",
      Cell: ({ row }) =>
        row.original.externalOrderNumber !== "" ? (
          <img
            className="order-edit"
            alt="edit_button"
            src={list_icon}
            onClick={() => onListIconClick(row)}
          />
        ) : null,
    })

    result.splice(0, 0, {
      Header: () => null,
      id: "cancel_order",
      Cell: ({ row }) =>
        row.original.orderStatus == "מחכה לאיסוף מספק" ? (
          <i
            className="fas fa-trash order-edit"
            aria-hidden="true"
            alt="cancel_order_button"
            onClick={() => onCancelOrderClick(row.original)}
          ></i>
        ) : null,
    })*/

    return result
  }, [])

  return (
    <div className="order-list-wrapper">
      <Container className="order-list">
        <TableContainer
          columns={columns}
          data={data}
          refreshTable={() => refreshTable()}
          sendLockerStatusByEmail={() => sendLockerStatusByEmail()}
          accountType={props.userData.accountType}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({ orderStatuses, users, lockersCells }) => {
  const { pudoLockers, lockerStatuesNames } = lockersCells
  const { userData } = users
  const { ordersStatusesArr } = orderStatuses
  return {
    pudoLockers,
    lockerStatuesNames,
    ordersStatusesArr,
    userData,
  }
}

export default connect(mapStateToProps)(LockersList)
