import React, { useEffect, useState } from "react"
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import "./style.scss"

const BarcodeScanner = ({ onScanBarcode }) => {
  //const [shouldShake, setShouldShake] = useState(false)

  const [scanner, setScanner] = useState(null)

  //const [scanedOrder, setScanedOrder] = useState(null)
  //const [orderScannedSuccess, setOrderScannedSuccess] = useState(false)

  const clearScanData = () => {
    //setOrderScannedSuccess(false)
  }

  const initScanner = () => {
    if (scanner == undefined) {
      let scn = new Html5QrcodeScanner("reader", {
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        qrbox: {
          width: 300,
          height: 150,
        },
        fps: 5,
      })
      setScanner(scn)
    }
  }

  useEffect(() => {
    initScanner()
    return () => {
      clearScanData()
    }
  }, [])

  useEffect(() => {
    if (scanner) {
      scanner.render(success, error)
      function success(decodedText, decodedResult) {
        scanner.clear()
        //setOrderScannedSuccess(false)
        onScanBarcode(decodedText)
      }
      function error(err) {
        console.log(err)
      }
    }
  }, [scanner])

  /* useEffect(() => {
    setShouldShake(true)
    const timeout = setTimeout(() => {
      setShouldShake(false)
    }, 500)
    return () => clearTimeout(timeout)
  }, [orderScannedSuccess])*/

  return (
    <div className="barcodeScanner">
      <div className="barcode-wrapper">
        <div>
          {
            //orderScannedSuccess ? (
            // <div className={`animated-div ${shouldShake ? "shake" : ""}`}>
            //   הזמנה {scanedOrder} נסרקה בהצלחה
            // </div>
            // ) : null
          }

          <div>
            <div id="reader" className="reader"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BarcodeScanner
