import axios from "axios"
import api from "../api/BaseApi"

import {
  saveResultInBgServiceKeyValue,
  readResultFromBgServiceKeyValue,
} from "../utils/LocalServiceOperations"
import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  CLEAR_LOCKERS_STATUSES,
  FETCH_PUDO_LOCKERS_STATUSES_SUCCESS,
  FETCH_LOCKERS_STATUSES_NAMES_SUCCESS,
  SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS,
  SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL,
  CLEAR_EMAIL_SEND,
  LOADING_EMAIL_SEND,
} from "./types"

//import { axiosGetRetry } from "../utils/ReqsRetry"
const ENABLE_BG_SYNC = false
export const fetchAllLockersStatuses = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.getLockersStatusesForStation(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const fetchPudoLockersStatuses = () => {
  return (dispatch) => {
    axios
      .get(api.getPudoLockersStatuses)
      .then((res) => {
        dispatch({
          type: FETCH_PUDO_LOCKERS_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const fetchLockersStatusesNames = () => {
  return (dispatch) => {
    axios
      .get(api.getLockersStatusesNames)
      .then((res) => {
        dispatch({
          type: FETCH_LOCKERS_STATUSES_NAMES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const fetchAllLockersLayout = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.getLockersLayoutByStationId(stationId))
      .then((res) => {
        const { layout } = res.data

        dispatch({
          type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
          payload: JSON.parse(layout),
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId),
            res.data
          )
        }
      })
      .catch((err) => {
        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId)
          )
            .then((savedVal) => {
              const { layout } = savedVal

              dispatch({
                type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
                payload: JSON.parse(layout),
              })
            })
            .catch((err) => console.log(err))
        }
        // USE WHEN OFFLINE..!
        // dispatch({
        //     type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
        //     payload: arr
        // });
      })
  }
}

export const clearLockersStatuses = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCKERS_STATUSES,
    })
  }
}

export const sendLockerStatusByEmail = () => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  return (dispatch) => {
    axios
      .post(api.sendPudoLockersStatusesByEmail, options)
      .then((res) => {
        dispatch({
          type: SEND_LOCKERS_STATUSES_BY_EMAIL_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_LOCKERS_STATUSES_BY_EMAIL_FAIL,
        })
      })
  }
}

export const clearEmailSend = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_EMAIL_SEND,
    })
  }
}

export const loadingEmailSend = () => {
  return (dispach) => {
    dispach({
      type: LOADING_EMAIL_SEND,
    })
  }
}
