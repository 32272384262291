import React, { Component } from "react"
import { connect } from "react-redux"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import Message from "../../common/Message"
import Title from "../../common/Title"
import {
  updateUserPassword,
  clearUserState,
  clearRequestData,
} from "../../../actions"
import eyeIcon from "../../../assets/images/eye.svg"

import "./style.css"

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isMatchError: false,
      inputType: "password",
      isShowPassword: false,
      isShowConfirmPassword: false,
      validField: {
        newPassword: {
          type: "password",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        confirmPassword: {
          type: "password",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        oldPassword: {
          type: "password",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
      },
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.state = this.INIT_STATE
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onChangePassBtn() {
    const {
      validField,
      //   oldPassword,
      newPassword,
      confirmPassword,
      isMatchError,
    } = this.state
    // this.validateInputValue("oldPassword", oldPassword)
    this.validateInputValue("newPassword", newPassword)
    this.validateInputValue("confirmPassword", confirmPassword)
    const isValid =
      //   validField.oldPassword.isChecked &&
      //   validField.oldPassword.isValid &&
      validField.newPassword.isChecked &&
      validField.newPassword.isValid &&
      validField.confirmPassword.isChecked &&
      validField.confirmPassword.isValid
    if (newPassword != confirmPassword) {
      this.setState({ isMatchError: true })
      return
    }
    if (!isValid) {
      return
    }
    const newUserData = {
      password: newPassword,
      id: this.props.userData.userId,
    }
    this.props.updateUserPassword(newUserData)
  }

  setValue(inputName, e) {
    switch (inputName) {
      case "oldPassword":
        this.setState({ oldPassword: e.target.value })
        break
      case "newPassword":
        this.setState({ newPassword: e.target.value })
        break
      default:
      case "confirmPassword":
        this.setState({ confirmPassword: e.target.value })
        break
    }
  }

  togglePassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword })
  }

  toggleConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword })
  }

  onMsgMatchClickOk(e) {
    this.setState({ isMatchError: false })
  }
  onMsgSuucessClickOk(e) {
    this.props.clearUserState()
    this.props.history.push("/")
  }

  render() {
    const { validField, isMatchError, isShowPassword, isShowConfirmPassword } =
      this.state
    const { createUpdateRequestError, createUpdateRequsetSuccess } = this.props

    return (
      <div>
        <Title text="שינוי סיסמה" />

        <div className="input-wrapper">
          <img
            className="eye-icon"
            src={eyeIcon}
            alt="show password icon"
            onClick={() => this.togglePassword()}
          />
          <InputField
            show={true}
            value={this.state.newPassword}
            placeholder="סיסמא חדשה"
            mandatory={true}
            showValidation={validField.newPassword.isChecked}
            showValidationOk={validField.newPassword.isValid}
            showValidationError={!validField.newPassword.isValid}
            validationErrorMessage="מינימום 5 תווים, לפחות אות אחת, לפחות ספרה אחת"
            onFocus={(e) => this.onInputFocus("panewPasswordssw")}
            onBlur={(e) =>
              this.validateInputValue("newPassword", e.target.value)
            }
            onChange={(e) => this.setValue("newPassword", e)}
            type={isShowPassword ? "text" : "password"}
            maxLength={20}
          />
        </div>
        <div className="input-wrapper">
          <img
            className="eye-icon"
            src={eyeIcon}
            alt="show confirm password icon"
            onClick={() => this.toggleConfirmPassword()}
          />
          <InputField
            show={true}
            value={this.state.confirmPassword}
            placeholder="אימות סיסמא"
            mandatory={true}
            showValidation={validField.confirmPassword.isChecked}
            showValidationOk={validField.confirmPassword.isValid}
            showValidationError={!validField.confirmPassword.isValid}
            validationErrorMessage="מינימום 5 תווים, לפחות אות אחת, לפחות ספרה אחת"
            onFocus={(e) => this.onInputFocus("confirmPassword")}
            onBlur={(e) =>
              this.validateInputValue("confirmPassword", e.target.value)
            }
            onChange={(e) => this.setValue("confirmPassword", e)}
            type={isShowConfirmPassword ? "text" : "password"}
            maxLength={20}
          />
        </div>

        <div className="button-wrapper">
          <Button
            show={true}
            text="שינוי סיסמה"
            onClick={(e) => this.onChangePassBtn()}
          />
        </div>
        <Message
          show={isMatchError}
          isError={true}
          successText=""
          errorText="סיסמאות לא תואמות"
          onClick={(e) => this.onMsgMatchClickOk(e)}
        />
        <Message
          show={createUpdateRequsetSuccess}
          isError={false}
          successText="סיסמתך שונתה בהצלחה"
          errorText=""
          onClick={(e) => this.onMsgSuucessClickOk(e)}
        />
        <Message
          show={createUpdateRequestError}
          isError={true}
          successText=""
          errorText="שגיאה, אנא נסה שנית או פנה לשירות לקוחות"
          onClick={(e) => this.props.clearRequestData()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const {
    authenticated,
    userData,
    isLoading,
    showMessage,
    createUpdateRequestError,
    createUpdateRequsetSuccess,
  } = users

  return {
    authenticated,
    userData,
    isLoading,
    showMessage,
    createUpdateRequestError,
    createUpdateRequsetSuccess,
  }
}

export default connect(mapStateToProps, {
  updateUserPassword,
  clearUserState,
  clearRequestData,
})(ChangePasswordScreen)
