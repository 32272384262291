import React, { Component } from "react"
import "./style.scss"

class SubTitle extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { text, ...props } = this.props
    return <div className="sub-title">{text} </div>
  }
}

export default SubTitle
