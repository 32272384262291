import axios from "axios"
import api from "../api/BaseApi"
//import errorManager from '../utils/ErrorManager';

import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  ON_USER_SIGN_IN_REQUEST,
  ON_CREATE_UPDATE_USER_REQUEST,
  ON_UPDATE_USER_SUCCESS,
  ON_CREATE_UPDATE_USER_ERROR,
  CLEAR_USER_STATE,
  CLEAR_REQUEST,
  ON_RESET_PASSWORD_SUCCESS,
  ON_RESET_PASSWORD_FAIL,
} from "./types"

export const onUserSignInRequest = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    })
  }
}

export const onUserSignInClick = (name, pwd) => {
  return (dispatch) => {
    axios
      .post(api.signIn, api.signInBody(name, pwd))
      .then((res) => {
        const {
          token,
          firstName,
          lastName,
          stationId,
          branchName,
          stationNumber,
        } = res.data
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            firstName,
            lastName,
            stationId,
            branchName,
            stationNumber,
          },
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          dispatch({
            type: ON_USER_SIGN_IN_FAIL,
            payload: {
              errTitle: "err_title_text",
              errText: "network_error_text",
              userData: null,
            },
          })

          return
        }

        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: {
            userData: null,
            errTitle: "err_title_text",
            errText: "wrong_login_err_body_text",
          },
        })
      })
  }
}

export const onUserSignOut = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_OUT,
    })
  }
}

export const updateUserPassword = (updatedUser) => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_UPDATE_USER_REQUEST,
    })

    axios
      .put(api.updateUserPassword(), JSON.stringify(updatedUser), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        dispatch({
          type: ON_UPDATE_USER_SUCCESS,
        })
      })
      .catch((err) => {
        console.log("ERROR AT: updateUserData", err)

        dispatch({
          type: ON_CREATE_UPDATE_USER_ERROR,
        })
      })
  }
}

export const clearUserState = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_STATE,
    })
  }
}

export const clearRequestData = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REQUEST,
    })
  }
}

export const resetPassword = (userId) => {
  return (dispatch) => {
    axios
      .put(api.resetPassword(userId), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: ON_RESET_PASSWORD_SUCCESS,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_RESET_PASSWORD_FAIL,
        })
      })
  }
}
